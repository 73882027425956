import React from 'react';

function Workplace() {
    return(
        <div>
            Workplace
        </div>
    );
}

export default Workplace;